import {
  IDropdown, IModal, IPopup,
} from '@/components';
import {
  IInput, ICheckboxes, ITextarea, ISelect, IRadios,
} from '@/components/form';
import router from '@/router';
import { IVisitor } from '@/models/visitor';
import store from '@/store';
import { ITranslations } from '@/translations/index';

export const attendingInputs: Array<IInput | IRadios | ICheckboxes | ITextarea | ISelect> = [];

export const visitorSettingsPopup: IPopup = {
  message: '',
  type: '',
};

export interface IVisitorForm {
  name: IInput;
}

export const inputs = () => {
  const translations: ITranslations = store.getters.getTranslations;
  const inputs: IVisitorForm = {
    name: {
      id: 'visitor-onboarding-name',
      name: 'visitor_onboarding_name',
      type: 'text',
      label: translations.common.name,
      placeholder: translations.common.name_placeholder,
      value: '',
    },
  };
  return inputs;
};
export const basicForm = {
  name: '',
};

export const interactionSettingsModal: IModal = {
  isActive: true,
  close: () => {
    router.push('/');
  },
};

export const visitorModel: IVisitor = {
  id: 0,
  name: '',
  email: '',
  event: 0,
  status: 'registered',
};

export const deviceActionPopup: IPopup = {
  message: '',
  type: '',
};

export const dropdown: IDropdown = {
  isActive: false,
  position: 'right',
};
