import { MutationPayload } from 'vuex';
import { defineComponent } from '@vue/composition-api';
import { IAuth } from '@/models/auth';
import { editUser } from '@/api/users';
import Input from '@/components/form/input.vue';
import Modal from '@/components/modal.vue';
import Cropper from '@/components/form/cropper.vue';
import store from '@/store';
import {
  updateEventAttendingAnswers,
} from '@/api/visitors';
import { uploadFile } from '@/api/files';
import {
  inputs, basicForm,
} from '.';
import useContext, { IUseContext } from '@/composition/context';
import useForm from '@/composition/form';
import useAuth from '@/composition/auth';
import RegistrationForm from '@/partials/RegistrationForm.vue';
import router from '@/router';

export default defineComponent({
  components: {
    Input,
    Modal,
    Cropper,
    RegistrationForm,
  },
  setup() {
    const { contextEvent, translations, contextVisitor } = useContext() as unknown as IUseContext;
    const { currentUser, userAuthState } = useAuth();

    const {
      buildForm,
      getAnswers,
      validateForm,
      disabled,
      form,
    } = useForm();

    const state = {
      loadingBasic: false,
      inputs: inputs(),
    };

    const syncVisitorName = (name: string) => {
      if (contextVisitor.value.id) {
        store.commit('setContextVisitor', { ...contextVisitor.value, name });
      }
    };

    const saveAllFields = async () => {
      form.loading = true;

      // Currently the attending name equals a beamian user name
      if (basicForm.name && basicForm.name !== '') {

        const user = {
          id: currentUser.value.id,
          name: basicForm.name,
          email: currentUser.value.email ? currentUser.value.email.toLowerCase() : undefined,
        };

        await editUser(user).catch(() => {
          store.commit('addPopup', {
            type: 'danger',
            message: translations.value.common.something_went_wrong,
            autohide: true,
          });
        });

        const auth: IAuth = { token: userAuthState.value.token, user };
        store.commit('setUserLogin', auth);
        syncVisitorName(user.name);
      }

      saveAttending();

    };

    const saveAttending = async () => {
      form.loading = true;

      validateForm();

      if (!form.invalid && contextVisitor.value.id) {
        for (const [i, field] of form.fields.entries()) {
          if (field.attending_field.field_type === 'file' && field.value && field.value !== '') {
            // todo: refactor, this is poop :(, in order to not lose file values
            // Check if the value is id from the previous file
            if (!Number(field.value)) {
              const imageForm = new FormData();
              imageForm.append('file', field.value as string);
              // eslint-disable-next-line no-await-in-loop
              const response = await uploadFile(imageForm);

              form.fields[i] = {
                ...field,
                value: response.data.id,
              };
            }
          }
        }
        updateEventAttendingAnswers(contextEvent.value.id, contextVisitor.value.id, form.fields)
          .then((response) => {
            console.log(response);
            form.loading = false;
          }).catch((err) => {
            const errors = err.response.data;
            if (errors && errors.length > 0) {
              errors.forEach((error: string) => {
                store.commit('addPopup', {
                  message: error,
                  type: 'danger',
                  autohide: true,
                });
              });
            } else {
              store.commit('addPopup', {
                message: err.message,
                type: 'danger',
                autohide: true,
              });
            }
            form.loading = false;
          });
      } else {
        form.loading = false;
        window.scrollTo(0, 0);
      }
    };
    const close = () => {
      router.push(`/event/${contextEvent.value.id}/profile/${contextVisitor.value.id}`);
    };

    const initVisitor = () => {
      form.loading = true;

      state.inputs.name = { ...state.inputs.name, value: contextVisitor.value.name };
      basicForm.name = contextVisitor.value.name;
      state.inputs = {
        ...state.inputs,
        name: {
          ...state.inputs.name,
          value: contextVisitor.value.name,
        },
      };

    }

    if (contextVisitor.value.id) {
      initVisitor();
    }
    if (contextEvent.value.id) {
      buildForm('form').then(() => {
        getAnswers();
      });
    }
    const unsubscribe = store.subscribe((mutation: MutationPayload) => {
      if (mutation.type === 'setContextVisitor') {
        initVisitor();
        unsubscribe();
      }
    });
    const unsubscribe2 = store.subscribe((mutation: MutationPayload) => {
      if (mutation.type === 'setLanguage') {
        buildForm('form').then(() => {
          getAnswers();
        });
        unsubscribe2();
      }
    });

    return {
      contextEvent,
      translations,
      state,
      form,
      disabled,
      basicForm,
      currentUser,
      saveAllFields,
      saveAttending,
      close,
    };
  },

});
